/** Mixin for data editor specific methods */
export default {

	inject: {
		getSource: {
			default() {
				return () => { return null };
			}
		}
	},

	methods: {

		removeProperty(sub, container) {
			if (container === undefined) container = this.data;
			const property = sub.property;
			const hasSource = !!this.getSourceOfUIModel(sub);
			const hasExistingData = this.uimodel?.savedData?.[property] || this.uimodel.inheritedData?.[property];
			if (hasSource || hasExistingData) {
				//if the property has existing data, set it to null to mark it for deletion by config service
				this.$set(container, property, null);
			} else {
				this.$delete(container, property);
			}
			if (!Number.isNaN(this.update)) this.update++;
		},

		restoreProperty(sub, container) {
			if (container === undefined) {
				// if a sub property wants to exist, we need to ensure that this.data can take it
				if (this.data === undefined || this.data === null) this.data = {};
				container = this.data;
			}
			this.$set(container, sub.property, this.$cloneObject(sub.savedData));
			if (!Number.isNaN(this.update)) this.update++;
		},

		getOrderedProperties(properties) {
			//Sort properties according to their sort order
			if (!properties || !this.$isPlainObject(properties)) return undefined;
			const orderedProperties = Object.entries(properties).sort(([, a], [, b]) => {
				const sortOrderA = this.getSortOrderNumber(a);
				const sortOrderB = this.getSortOrderNumber(b);
				return sortOrderA - sortOrderB; //ascending
			});
			return Object.fromEntries(orderedProperties.map(([property, value]) => {
				return [property, value];
			}));
		},

		getSortOrderNumber(property) {
			const sortOrderNr = Number(property?.dataUI?.sortOrder);
			if (Number.isNaN(sortOrderNr)) return 99999;
			return sortOrderNr;
		},

		buildSubUimodels(uimodel) {
			//Build the child ui models 
			const schema = uimodel.schema;
			/**** ARRAY TYPE HANDLING *******/
			if (schema?.type === 'array') return this.buildArrayUiModels(uimodel)
			if (schema?.type !== 'object') return {};
			return this.buildObjectUiModels(uimodel, schema)
		},

		buildObjectUiModels(uimodel, schema, possibleSchemas) {
			let all = {};
			// 1. build models for existing schema properties
			if (schema?.properties) {
				const schemaProperties = this.getOrderedProperties(schema.properties);
				for (let p in schemaProperties) {
					all[p] = {
						property: p,
						data: undefined,
						domain: undefined,
						savedData: undefined,
						savedDomain: undefined,
						inheritedData: undefined,
						inheritedDomain: undefined,
						schema: schemaProperties[p],
						isAdditional: false,
						default: schemaProperties[p]?.default ?? schema?.default?.[p]
					};
				}
			}

			const additionalProperties = schema?.additionalProperties ?? undefined;
			if (additionalProperties && additionalProperties === true) {
				//if additionalProperties does not define a specific schema, enable manual type selection
				possibleSchemas = [
					{ type: "string" },
					{ type: "number" },
					{ type: "boolean" },
					{ type: "object", additionalProperties: true },
					{ type: "array" },
				];

			}


			//2. add merged data
			//so that every existing data in the object has its uimodel
			for (let p in uimodel.mergedData) {
				all[p] = {
					property: p,
					data: undefined,
					domain: undefined,
					savedData: all[p]?.savedData,
					savedDomain: all[p]?.savedDomain,
					inheritedData: all[p]?.inheritedData,
					inheritedDomain: all[p]?.inheritedDomain,
					schema: all[p]?.schema,
					mergedData: this.$cloneObject(uimodel.mergedData?.[p]),
					isAdditional: all[p]?.isAdditional ?? true,
					default: all[p]?.default
				};
			}

			// 3. add unsaved properties
			// go through the (potentially unsaved) data on the current domain level
			for (let p in uimodel.data) {
				let currentSchema = all[p]?.schema;
				if ((!currentSchema) && additionalProperties) {
					//get schema of additional properties
					const type = this.$getType(uimodel.data[p])
					currentSchema = (additionalProperties === true ? possibleSchemas.find(s => s.type === type) : additionalProperties);
				}

				all[p] = {
					property: p,
					data: uimodel.data[p],
					domain: uimodel.editedDomain,
					savedData: all[p]?.savedData,
					savedDomain: all[p]?.savedDomain,
					inheritedData: all[p]?.inheritedData,
					inheritedDomain: all[p]?.inheritedDomain,
					schema: currentSchema,
					mergedData: all[p]?.mergedData,
					isAdditional: all[p]?.isAdditional ?? true,
					default: all[p]?.default
				};
			}

			const isI18n = uimodel?.schema?.additionalPropertiesTypeKey === "i18n";

			// 4. add saved and inheritance data, as well as some other stuff
			for (let p in all) {

				if (all[p]?.schema?.dataUI?.hidden === true) {
					//if property is not hidden, remove the model
					delete all[p];
					continue;
				}

				//Mark internationalizations as such, since they are treated differently
				//than other additionalProperties
				all[p].isI18n = isI18n;

				// for some checks we need to know information of the parent.
				// e.g. to prevent bubble-back on an undefined parent data
				const parentData = uimodel.data !== undefined ? this.$cloneObject(uimodel.data) : undefined;
				const parentSchema = uimodel.schema !== undefined ? this.$cloneObject(uimodel.schema) : undefined;
				const parentIsArrayItem = uimodel.parentSchema?.type === "array"
				all[p].parentData = parentData
				all[p].parentSchema = parentSchema
				all[p].parentIsArrayItem = parentIsArrayItem;

				const path = uimodel.path + "/" + p
				all[p].path = path;

				//SAVED DATA
				const savedData = this.$cloneObject(uimodel?.savedData?.[p]);
				all[p].savedData = savedData;
				all[p].savedDomain = uimodel.domain;

				//INHERITED DATA
				let source = this.getSourceOfUIModel(all[p]);
				if (!source && this.$isPlainObject(uimodel.inheritedData)) {
					//if no source exists, check if the inherited data 
					//can be fetched from the parent object
					source = {
						inheritValue: this.$cloneObject(uimodel.inheritedData?.[p]),
						inheritSourceDomainId: uimodel.inheritSourceDomainId,
						inheritSourceKeyPattern: uimodel.inheritSourceKeyPattern
					}
				}

				if (!source && (all[p]?.mergedData !== undefined && all[p]?.mergedData !== null)) {
					//if source does still not exist, check also if the inherited data 
					//can be fetched from the merged data
					source = {
						inheritValue: all[p]?.mergedData,
						inheritSourceDomainId: undefined,
						inheritSourceKeyPattern: undefined
					}
				}

				const inheritedData = source?.inheritValue;
				all[p].inheritedData = inheritedData;
				all[p].inheritedDomain = source?.inheritSourceDomainId ?? source?.sourceDomainId;
				all[p].inheritedKeyPattern = source?.inheritSourceKeyPattern ?? source?.sourceKeyPattern;

				all[p].parentInheritable = uimodel.inheritable;
				let inheritable = uimodel.inheritable ?? true;
				if (!all[p].isI18n && all[p].isAdditional && inheritedData === undefined) {
					//if the property is an additional property and has no inherited data
					//it is not inheritable
					inheritable = false;
				}

				all[p].inheritable = inheritable;

				// If no schema is present and additionalProperties are enabled, 
				// get the schema from the additional properties or existing data
				if (!all[p]?.schema && additionalProperties) {
					const type = this.$getType(savedData) ?? this.$getType(inheritedData)
					all[p].schema = (additionalProperties === true ? possibleSchemas.find(s => s.type === type) : additionalProperties);
				}

				/*
					Check if the given ui model contains invalid data
					Data is invalid if the given uimodel is not specified in the schema and... :
					
					1. additionalProperties is false or undefined
					2. additionalProperties is a defined schema and its type is different to the type of the given data
				
				*/
				if (all[p].schema === undefined && all[p].isAdditional && additionalProperties !== true && all[p].data !== undefined) {
					const dataType = this.$getType(all[p].data);
					if (all[p].schema === undefined || additionalProperties === false || dataType !== additionalProperties?.type || (additionalProperties.type === "integer" && !Number.isInteger(all[p].data))) {
						//the data is invalid, so set the isInvalid flag and define the schema
						//to enure the correct display in the editor
						all[p].isInvalid = true;
						all[p].schema = { type: dataType }
					}
				}

				//only add possible schemas if the uimodel is an additional property
				if (all[p].isAdditional) all[p].possibleSchemas = possibleSchemas;

				//Edge cases for additional properties in array items
				if (all[p].isAdditional && !all[p].inheritable) {
					//The model is an additional property, whose parent IS NOT inherited, the model itself is not inheritable (e.g. as item inside an array)
					// and it has no data except inherited data 
					// -> The model does not exist on this domain, so remove it
					const onlyInheritedData = all[p].inheritedData !== undefined && all[p].savedData === undefined && all[p].data === undefined && all[p].parentData !== undefined;

					//The model is an additional property whose parent IS inherited, the model itself is not inheritable (e.g. as item inside an array)
					// and it has no inherited data itself (e.g. as item inside an array)
					// -> It exists only on this domain, so remove the model
					const noInheritedData = all[p].parentData === undefined && all[p].inheritedData === undefined;

					if (onlyInheritedData || noInheritedData) {
						delete all[p];
						continue;
					}
				}

				//if the property is an additional property check if it has saved data and no current data
				// -> if this is true, it means that the property was deleted, so mark the model as removed
				if (all[p].isAdditional && !all[p].inheritable && (all[p].data === undefined || all[p].data === null)) {
					all[p].removed = true;

					if (all[p].savedData !== undefined) {
						//set the schema according to the data type to ensure the correct display
						all[p].schema = { type: this.$getType(all[p].savedData) }
					}
				}

				all[p].key = uimodel.key;
				all[p].keySpace = uimodel.keySpace;
				all[p].domainLevel = uimodel.domainLevel;
				all[p].dataLevel = uimodel.dataLevel + 1;
				all[p].editedDomain = uimodel.editedDomain;
				all[p].required = !!schema?.required?.includes(p);
				//Currently, only the english version is used as label
				all[p].label = all[p]?.schema?.title?.values?.["en-GB"] ?? all[p].property;

				const readonly = all[p].schema?.dataUI?.readonly;
				const editable = all[p].schema?.dataUI?.editable;

				//Check editable behaviour
				if (!uimodel.editable && editable !== true) {
					//if parent is not editable, the current property is too
					all[p].editable = false
				} else {
					//if parent ui model is editable, check if dataUI is defined
					//in the current properties schema
					all[p].editable = editable ?? (readonly !== true);
				}

			}

			return all;

		},

		buildArrayUiModels(uimodel) {
			const schema = uimodel.schema;
			let all = {};
			//create uimodels for the array 
			//As of now, arrays are completly overriden from one domain to another
			//so it needs a different inheritance logic than other types
			let itemSchema = undefined;
			let possibleSchemas = undefined;
			if (schema?.items) {
				itemSchema = schema?.items;
			}
			else {
				if (!schema?.items) {
					possibleSchemas = [
						{ type: "string" },
						{ type: "number" },
						{ type: "boolean" },
						{ type: "object", additionalProperties: true },
						{ type: "array" },
					];
				}
			}

			//1. Get items of current data to determine, which items are currently set
			// -> this will later be used to compare with the saved data on the domain
			if (uimodel.data) {
				uimodel.data.forEach((item, index) => {
					all[index] = {
						property: index,
						data: item,
						domain: uimodel.editedDomain,
						inheritedData: undefined,
						inheritedDomain: undefined,
						schema: itemSchema ?? possibleSchemas?.find(s => s.type === this.$getType(item)),
						isAdditional: true,
					};
				});
			}

			//2. ADD SAVED DATA
			const savedData = this.$cloneObject(uimodel?.savedData);
			if (Array.isArray(savedData)) {
				savedData.forEach((item, index) => {
					//only update/build UI models for indices which exist in the current data
					//--> this enures that deleted items are not shown in the UI
					if (all[index] === undefined) return;

					//if no schema is given, guess the schema according to the data or inherited data
					const schema = itemSchema ?? possibleSchemas.find(s => s.type === this.$getType(all[index]?.data ?? all[index]?.inheritedData));

					all[index] = {
						property: index,
						data: all[index]?.data,
						domain: all[index]?.domain,
						inheritedData: undefined,
						inheritedDomain: undefined,
						savedData: this.$cloneObject(item),
						savedDomain: uimodel.domain,
						schema,
						isAdditional: true,
					}
				})
			}

			//3. ADD INHERITED DATA
			const source = this.getSourceOfUIModel(uimodel);
			const inheritedData = source?.inheritValue ?? this.$cloneObject(uimodel.inheritedData);
			const inheritedDomain = source?.inheritSourceDomainId;
			const inheritedKeyPattern = source?.inheritSourceKeyPattern;
			if (Array.isArray(inheritedData)) {
				inheritedData.forEach((item, index) => {
					//only update/build UI models for indices whose data or parentData is undefined
					//--> this enures that deleted items are not shown in the UI
					if ((all[index]?.data !== undefined && all[index]?.data !== null) || (uimodel.data !== undefined && uimodel.data !== null)) return;

					const schema = itemSchema ?? possibleSchemas.find(s => s.type === this.$getType(item));

					//although inheritance for single items is not supported in the configuration service,
					//still add the inherited data, so that the correct data is shown in the UI, when the array is inherited
					all[index] = {
						property: index,
						data: all[index]?.data,
						domain: all[index]?.domain,
						inheritedData: this.$cloneObject(item),
						inheritedDomain,
						inheritedKeyPattern,
						savedData: all[index]?.savedData,
						savedDomain: all[index]?.savedDomain,
						schema,
						isAdditional: true,
					}
				});
			}


			for (let index in all) {

				all[index].domainLevel = uimodel.domainLevel;
				all[index].dataLevel = uimodel.dataLevel + 1;
				// for some checks we need to know the data of the parent.
				// mainly this is to prevent bubble-back on an undefined parent data
				const parentData = uimodel.data !== undefined ? this.$cloneObject(uimodel.data) : undefined;
				const parentSchema = uimodel.schema !== undefined ? this.$cloneObject(uimodel.schema) : undefined;
				all[index].parentData = parentData;
				all[index].parentSchema = parentSchema;
				all[index].editedDomain = uimodel.editedDomain;
				all[index].possibleSchemas = possibleSchemas;

				//only whole arrays may be inherited, so disable inheritance for all sub models of an array
				all[index].inheritable = false;

				all[index].path = uimodel.path + "[" + index + "]";

				all[index].key = uimodel.key;
				all[index].keySpace = uimodel.keySpace;
				all[index].label = all[index]?.schema?.title?.values?.["en-GB"] ?? all[index].property;

				const readonly = all[index].schema?.dataUI?.readonly;
				const editable = all[index].schema?.dataUI?.editable;

				//Check editable behaviour
				if (!uimodel.editable && editable !== true) {
					//if parent is not editable, the current property is too
					all[index].editable = false
				} else {
					//if parent ui model is editable, check if dataUI is defined
					//in the current properties schema
					all[index].editable = editable ?? (readonly !== true)
				}
			}

			return all;

		},

		getUiModelType(uimodel) {
			let type = uimodel.schema?.type;
			if (!uimodel.possibleSchemas) return type;

			//for type composit, calculate uimodel type according to the given data
			let data = null;
			if (this.uimodel.inheritedData !== undefined) data = uimodel.inheritedData
			if (this.uimodel.savedData !== undefined) data = uimodel.savedData
			if (this.data !== undefined) data = uimodel.data;
			type = this.$getType(data);

			//check if calculated type is one of the possible types
			if (uimodel?.possibleSchemas.some(item => item.type === type)) {
				return type;
			}

			//else return fallback
			//TODO: Is there a better solution?
			return uimodel.schema?.oneOf?.[0]?.type ?? 'string';
		},

		getInitialData(type, currentData) {
			let val = null;
			//set the value according to the type, but try to parse the current value first
			switch (type) {
				case 'string':
					//prevent val from being undefined since this will lead to unwanted side effects 
					//because a property with value undefined will be removed (see method setProperty)
					val = (currentData === undefined || currentData === null) ? "" : JSON.stringify(this.data);
					break;
				case 'number':
					val = Number(currentData);
					if (isNaN(val)) val = 0;
					break;
				case 'integer':
					val = Number(currentData);
					if (isNaN(val)) val = 0;
					break;
				case 'boolean':
					val = !!currentData;
					break;
				case 'object':
					try {
						val = JSON.parse(currentData);
						if (!this.$isPlainObject(val)) val = {}
					} catch (e) {
						val = {}
					}
					break;
				case 'array':
					try {
						val = JSON.parse(currentData);
						if (!Array.isArray(val)) val = []
					} catch (e) {
						val = [];
					}
					break;
			}
			return val;
		},

		getSourceOfUIModel(uimodel = {}) {
			const path = uimodel.path;
			const sourcePath = path?.replaceAll("/", ".");
			if (!sourcePath) return null;
			return this.getSource(sourcePath);
		}

	},

	computed: {
		ruleSet() {

			//helper functions
			const isUndefined = (val) => val === null || val === undefined;
			const floatSafeModulus = (val, step) => {
				//Safely get the modulus of numbers, since JS has problems with rounding floating numbers, 
				//so simple modulus will return invalid results, see https://stackoverflow.com/a/31711034
				const numVal = Number(val);
				const numStep = Number(step)
				const valDecCount = (numVal.toString().split('.')[1] || '').length;
				const stepDecCount = (numStep.toString().split('.')[1] || '').length;
				const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
				const valInt = parseInt(numVal.toFixed(decCount).replace('.', ''));
				const stepInt = parseInt(numStep.toFixed(decCount).replace('.', ''));
				return (valInt % stepInt) / Math.pow(10, decCount);
			}

			//possible validation rules for primitive type schemas
			return {
				required: (schema, uimodel) => {
					return (val) => {
						let isInherited = ((val === undefined || val === null) && !this.inheritDeactivatedManually);
						if (uimodel) {
							const parentData = uimodel.parentData;
							const parentInherited = parentData === null || parentData === undefined;
							const inheritable = uimodel.inheritable;
							isInherited = isInherited && (inheritable || parentInherited);
						}
						const strictRequired = !!schema?.strictRequired;
						if (!!val || val === 0 || (isInherited && !strictRequired)) return true;
						return 'Value is required'
					}
				},

				//Number
				integer: (val) => (isUndefined(val) || (Number.isInteger(Number(val))) || 'Must be an integer'),
				multipleOf: (schema) => { return (val) => (isUndefined(val) || (floatSafeModulus(val, schema.multipleOf) === 0)) || 'Must be multiple of ' + schema.multipleOf },
				minimum: (schema) => { return (val) => (isUndefined(val) || (Number(val) >= Number(schema.minimum))) || 'Must be greater than or equal ' + schema.minimum },
				exclusiveMinimum: (schema) => { return (val) => (isUndefined(val) || (Number(val) > Number(schema.exclusiveMinimum))) || 'Must be greater than ' + schema.exclusiveMinimum },
				maximum: (schema) => { return (val) => (isUndefined(val) || (Number(val) <= Number(schema.maximum))) || 'Must be less than or equal ' + schema.maximum },
				exclusiveMaximum: (schema) => { return (val) => (isUndefined(val) || (Number(val) < Number(schema.exclusiveMaximum))) || 'Must be less than ' + schema.exclusiveMaximum },

				//String
				minLength: (schema) => { return (val) => (isUndefined(val) || (val?.length >= Number(schema.minLength))) || 'Minimum ' + schema.minLength + ' character(s)' },
				maxLength: (schema) => { return (val) => (isUndefined(val) || (val?.length <= Number(schema.maxLength))) || 'Maximum ' + schema.maxLength + ' character(s)' },
				pattern: (schema) => { return (val) => (isUndefined(val) || (!!val?.match(schema?.pattern))) || 'Must match pattern ' + schema?.pattern },

				//Array
				minItems: (schema) => { return (val) => (!isUndefined(val) && val?.length >= Number(schema.minItems)) || 'Minimum ' + schema.minItems + ' item(s)' },
				maxItems: (schema) => { return (val) => (!isUndefined(val) && val?.length <= Number(schema.maxItems)) || 'Maximum ' + schema.maxItems + ' item(s)' }
			}
		},
	}
}